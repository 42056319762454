<template lang="pug">
  div.wrap-module-create-team
    div.header
      div.header-content.f.fm.flex-between  
        v-icon(@click="$router.go(-1)") mdi-chevron-left
        span.fz14 チームを編集
        span
    div.create-team-container.py14
      div
        v-text-field(label="チーム名 *" v-model="name"
          placeholder="チーム名を入力" persistent-placeholder autofocus)

      div.mb16
        span.label.fz12 メンバーシップドメイン
        div(v-for="(item, index) in membershipDomains", :key="index").membership-item.f.fm.flex-between.mb8
          input(v-model="membershipDomains[index]"
            placeholder="@example.com").px6.py4.mr4
          div.wrap-icon
            v-icon(@click.stop="removeMembershipDomainAtIndex(index)" size="20px") mdi-close
        //- v-text-field(
          v-for="(item, index) in membershipDomains"
          v-model="membershipDomains[index]"
          placeholder="@example.com"
          single-line
          append-outer-icon="mdi-close"
          @click:append-outer="removeMembershipDomainAtIndex(index)")
        span.fz10 * メンバーシップドメインを含むemailのユーザーがチームに追加されます
        div.f.flex-right.mt12
          span(@click="membershipDomains.push('')").btn-add.fz12.cursor-pointer ドメインを追加

      div.mb14
        v-textarea(label="メンバーの追加"
          v-model="invitedEmails"
          placeholder="user@example.com, user2@company.com" persistent-placeholder
          hint="* カンマ区切りで追加するメンバーのemailを入力してください" persistent-hint)

      v-list(v-if="members")
        v-list-item(v-for="item in members", :key="item.id")
          v-list-item-avatar
            v-img(:src="item.iconURL")
          v-list-item-content
            v-list-item-title {{ item.name }}
            v-list-item-subtitle {{ item.email }}
          v-list-item-action
            span(v-if="item.id === team.owner").fz12 Owner
            v-btn(v-else icon @click="removeMember(item)")
              v-icon(size="20px") mdi-close
      
      div.extra-bottom-padding

    ItemButtomBtn(:label="isCreatingTeam ? 'チームプランを開始する' : '保存する'" :active="name ? true : false" :loading="isSaving" @onBtn="save")
</template>

<style lang="scss">
@import '@/scss/_variables.scss';

.wrap-module-create-team {
  min-height: calc(100vh - #{$header_height});
  .header {
    width: 100%;
    border-bottom: $basic_border;
    .header-content {
      width: $base_width_percent;
      max-width: $base_max_width_px;
      height: $header_height;
      margin: 0 auto;
    }
  }
  .create-team-container {
    width: $base_width_percent;
    max-width: $base_max_width_px;
    margin: 0 auto;
    .label {
      color: $secondary_text_color;
    }
    .membership-item {
      input {
        width: 100%;
        border: $basic_border;
        border-radius: 6px;
      }
    }
    .btn-add {
      color: $active_color;
    }

    // .v-text-field--single-line {
    //   padding-top: 0;
    //   .v-text-field__details {
    //     height: 0;
    //     min-height: 0 !important;
    //   }
    // }
    .extra-bottom-padding {
      height: 60px;
    }
  }
}
</style>

<style lang="scss">
.wrap-module-create-team {
  .v-list-item {
    padding: 0;
  }
}
</style>

<script>
import database from '@/database'
import { createNamespacedHelpers } from 'vuex'
import { createSubscription, savePaymentMethodId } from '@/services/functions'
import { withoutDuplicates } from '../utils/array'
const { mapState: mapStateAuth } = createNamespacedHelpers('auth')
const teamStore = createNamespacedHelpers('team')
const planSettingsStore = createNamespacedHelpers('planSettings')

import ItemButtomBtn from '@/components/item/ItemButtomBtn'
import { isAfter } from 'date-fns'
import { sendTrackingEvent } from '../utils/eventTracker'

export default {
  components: {
    ItemButtomBtn
  },
  computed: {
    ...mapStateAuth(['uid']),
    ...planSettingsStore.mapState(['planSettings']),
    isCreatingTeam() {
      return this.$route.path.includes('create')
    }
  },
  data() {
    return {
      /**
       * @type {ITeamModel}
       */
      team: null,
      members: [],
      name: '',
      membershipDomains: [''],
      memberUids: [],
      invitedEmails: '',
      isSaving: false
    }
  },
  async created() {
    this.team = await database.teamCollection().findAnyTeamByOwnerId(this.uid)
    if (this.team) {
      this.name = this.team.name
      this.membershipDomains =
        this.team.membershipDomains.length > 0
          ? this.team.membershipDomains.map((v) => `@${v}`)
          : ['']
      this.memberUids = this.team.members.filter((v) => v !== this.team.owner)

      const owner = await database.userCollection().findById(this.team.owner)
      this.members = [owner]

      const members = await database
        .userCollection()
        .findListByIds(this.team.members.filter((v) => v !== this.team.owner))
      this.members = [owner, ...members]
    }
  },
  methods: {
    ...teamStore.mapActions(['loadTeamByUid']),
    removeMembershipDomainAtIndex(index) {
      this.membershipDomains = this.membershipDomains.filter((_, _index) => _index !== index)
    },
    removeMember(member) {
      this.members = this.members.filter((v) => v.id !== member.id)
      this.memberUids = this.memberUids.filter((v) => v !== member.id)
    },
    async save() {
      sendTrackingEvent(`${this.$route.path}:save`, { step: 'start' }, this.uid)
      const membershipDomains = this.membershipDomains
        .map((v) => (v.startsWith('@') ? v.substr(1) : v))
        .filter((v) => !!v)

      const invitedEmails = withoutDuplicates([
        ...(this.team ? this.team.invitedEmails : []),
        ...this.invitedEmails.split(',').map((v) => v.trim())
      ])
        .filter((v) => !!v)
        .filter((v) => !this.members.find((m) => m.email === v))

      const invitedMembers =
        invitedEmails.length > 0
          ? await database.userCollection().findListByEmails(invitedEmails)
          : []
      const invitedMemberIds = invitedMembers.map((v) => v.id)

      const invitedNewEmails = invitedEmails.filter(
        (v) => !invitedMembers.find((_v) => _v.email === v)
      )

      this.isSaving = true
      if (this.team) {
        sendTrackingEvent(`${this.$route.path}:save`, { step: 'update team' }, this.uid)
        await database.teamCollection().update(this.team.id, {
          name: this.name,
          membershipDomains,
          members: withoutDuplicates([...this.memberUids, ...invitedMemberIds]),
          invitedEmails: invitedNewEmails
        })
      } else {
        sendTrackingEvent(`${this.$route.path}:save`, { step: 'create team' }, this.uid)
        await database.teamCollection().add({
          owner: this.uid,
          name: this.name,
          membershipDomains,
          members: withoutDuplicates([...this.memberUids, ...invitedMemberIds]),
          invitedEmails: invitedNewEmails,
          couponCode: this.planSettings.couponCode || null,
          createdBy: this.uid,
          createdAt: new Date()
        })
      }

      if (this.planSettings && this.planSettings.stripePaymentMethodId) {
        sendTrackingEvent(`${this.$route.path}:save`, { step: 'save payment method' }, this.uid)
        await savePaymentMethodId({
          paymentMethodId: this.planSettings.stripePaymentMethodId
        })
      }

      // create subscription if needed
      if (this.$route.path.includes('create')) {
        let shouldSkipCreateSubscripion = false
        if (this.team && this.team.downgradedAt) {
          let downgradedAt = this.team.downgradedAt.toDate()
          if (isAfter(downgradedAt, new Date())) {
            // subscription is not cancelled because downgradedAt is in future!!!
            // we just need remove downgradedAt from team
            sendTrackingEvent(`${this.$route.path}:save`, { step: 'remove downgradedAt' }, this.uid)
            shouldSkipCreateSubscripion = true
            await database.teamCollection().update(this.team.id, {
              downgradedAt: null,
              couponCode: this.planSettings.couponCode || this.team.couponCode || null
            })
          }
        }

        if (!shouldSkipCreateSubscripion) {
          sendTrackingEvent(`${this.$route.path}:save`, { step: 'create subscription' }, this.uid)
          await createSubscription({
            couponCode: this.planSettings.couponCode
          })
        }
      }

      sendTrackingEvent(`${this.$route.path}:save`, { step: 'reload team' }, this.uid)
      await this.loadTeamByUid(this.uid)
      this.isSaving = false

      if (this.$route.path.includes('create')) {
        alert('チームの作成が完了しました')
        this.$router.push('/account')
      } else {
        this.$router.back()
      }
      sendTrackingEvent(`${this.$route.path}:save`, { step: 'finish' }, this.uid)
    }
  }
}
</script>
